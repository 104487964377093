<template>
  <div class="change-password">
    <!-- <el-button type="text" @click="dialogVisible=true">修改密码</el-button> -->
    <el-dialog :title="$t('userCenter.changeTitle')" :visible.sync="dialogVisible" custom-class="v-dialog v-dialog--xs v-dialog--footer v-dialog--lgpadding" :close-on-click-modal="false" append-to-body @close="beforeClose">
      <el-form ref="refForm" :model="form" status-icon :rules="rules" label-width="100px">
        <el-form-item :label="$t('userCenter.newTitle')" prop="title">
          <el-input type="text" maxlength="30" v-model.trim="form.title" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="customStyle" type="text" size="medium" @click="dialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button class="customStyle" plain type="primary" size="medium" @click="change" :loading="btnLoading">{{$t('common.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ModifyPlatformTitle } from '@/api/info'
import {mapMutations,mapGetters} from 'vuex'
import { setLastLoginInfo, getLastLoginInfo } from '@/common/cache'
export default {
  data() {
    return {
      btnLoading: false,
      form: {
        title: '',
      },
      dialogVisible: false,
      rules: {
        title: [
          { required: true, message: this.$t('userCenter.emptyWarning'), trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ])
  },
  created() {

  },
  methods: {
    ...mapMutations({
      'setLoginInfo': 'SET_LOGIN_INFO'
    }),
    okHandleValidate() {
      const promise = new Promise((resolve, reject) => {
        this.$refs.refForm.validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })

      return promise
    },

    async change() {
      let valid
      try {
        valid = await this.okHandleValidate()
      } catch (e) {
        valid = false
      }

      console.log(valid)
      if (!valid) {
        return
      }

      this.btnLoading = true

      ModifyPlatformTitle(this.form.title).then(({data}) => {
        if (data.errCode) {
          this.$message(data.errMsg)
        } else {
          this.$message({ message: this.$t('common.success'), type: 'success' })
          this.setTitle(this.form.title)
          document.title = this.form.title
          // 保存最后登录信息(标题，图标)
          const lastLoginInfo = getLastLoginInfo()
          lastLoginInfo.PlatformTitle = this.form.title
          setLastLoginInfo(lastLoginInfo)

          this.dialogVisible = false
        }
        this.btnLoading = false
      }).catch(err => {
        this.$message(err)
        this.btnLoading = false
      })
    },

    beforeClose() {
      this.form = {
        title: ''
      }
      this.$refs.refForm.resetFields()
    },
    open() {
      this.form.title = this.loginInfo.PlatformTitle
      this.dialogVisible = true
    },
    setTitle(t) {
      const info = Object.assign({}, this.loginInfo, {
        PlatformTitle: t
      })
      this.setLoginInfo(info)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
.change-password {
  padding-left: 20px !important;
  padding-right: 0 !important;

  & ::v-deep .el-button {
    color: $--color-text-secondary !important;
    &:hover {
      color: $--color-primary !important;
    }
  }
}
</style>
